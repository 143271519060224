import styled from 'styled-components';

const ArrowDownwardIcon = styled.img`
  width: 30.3px;
  position: relative;
  height: 31.1px;
`;
const ArrowdownRoot = styled.a`
  width: 85px;
  border-radius: 50px;
  background-color: #29529f;
  height: 85px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ArrowDown = ({ children, ...props }) => {
  return (
    <ArrowdownRoot {...props}>
      <ArrowDownwardIcon alt="" src="/arrow-downward.svg" />
    </ArrowdownRoot>
  );
};

export default ArrowDown;
