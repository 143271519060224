import Carousel from "react-material-ui-carousel";
import * as S from "./RocketBot.styles";
import { useState } from "react";
// import { Link } from "react-router-dom";
import IndexChat from "../IndexChat";

const GyaBot = () => {
  const [plan, setPlan] = useState(0);

  const handleClick = (planNumber) => {
    setPlan(planNumber);
  };

  return (
    <S.Container>
      <S.Header>
        <div className="logo-name">
          <img src="/gya.png" alt="Rocketbot logo" />
        </div>
        <h2>El chatbot con inteligencia artificial de ChatGPT</h2>
        <p>
          Eleva la experiencia de tus usuarios con nuestra inteligencia
          artificial de última generación
        </p>
        <a href="/contact">
          <S.Button type="button">Agendar demo</S.Button>
        </a>
      </S.Header>
      <S.AiPower>
        <IndexChat />
        <h2>¡Desata el poder la IA en tu negocio!</h2>
        <div className="subtitle">
          <p>¿En que te puede ayudar</p>
          <img src="/gya.png" alt="Rocketbot logo" />
          <span>
            rocket<b>bot</b>
          </span>
          <p> a ti?</p>
        </div>
        <div className="bullets-container">
          <div className="bullet">
            <img src="/Impulsa ventas.svg" alt="graph" />
            <h3>Impulsa ventas</h3>
            <small>
              Interactúa con un número ilimitado de usuarios sin comprometer la
              calidad
            </small>
          </div>{" "}
          <div className="bullet">
            <img src="/reten-clientes.svg" alt="clients" />
            <h3>Reten clientes</h3>
            <small>
              Atención ultra personalizada con información específica del
              cliente
            </small>
          </div>{" "}
          <div className="bullet">
            <img src="/impruve-service.svg" alt="like" />
            <h3>Mejora el servicio</h3>
            <small>
              Reducción de tiempos de espera y mejora de la experiencia
            </small>
          </div>
        </div>
      </S.AiPower>
      <S.HowItWorks>
        <h2>¿Cómo se entrena Rocketbot?</h2>
        <Carousel
          activeIndicatorIconButtonProps={{
            style: { color: "blue" },
          }}
        >
          <div className="bullet">
            <div className="gpt">
              <img src="/gpt-logo.svg" alt="gpt" />
              <p>Open AI / Chat GPT</p>
            </div>
            <div className="division" />
            <div className="list">
              <ul>
                <li>Procesador de lenguaje natural</li>
                <li>Machine learning</li>
              </ul>
            </div>
          </div>
          <div className="bullet">
            <div className="gpt">
              <img src="/rocketbot-typologo.svg" alt="rocketbot" />
            </div>
            <div className="division" />
            <div className="list">
              <ul>
                <li>Reglas de negocio</li>
                <li>Conversaciones estructuradas</li>
                <li>Integración con sistemas</li>
              </ul>
            </div>
          </div>
          <div className="bullet">
            <div className="gpt">
              <img src="/neuralnet.svg" alt="neuralnet" />
              <p>Fine tuning</p>
            </div>
            <div className="division" />
            <div className="list">
              <ul>
                <li>Tu compañía</li>
                <li>Tus productos</li>
                <li>Tus procesos</li>
                <li>Tu cultura</li>
                <li>Tu branding</li>
              </ul>
            </div>
          </div>
        </Carousel>
      </S.HowItWorks>
      <S.Plans>
        <h2>Nos ajustamos a ti</h2>
        <p>Contamos con diferentes opciones para facilitar tu potencial</p>
        <div className="plans-container">
          <div className="plans">
            <button type="button" onClick={() => handleClick(0)}>
              <div className="plan">
                <img src="/rocket-icon.svg" alt="rocket" />
                <p
                  style={
                    plan === 0
                      ? { textDecoration: "underline", color: "red" }
                      : { textDecoration: "none", color: "white" }
                  }
                >
                  Starter
                </p>
              </div>
            </button>
            <button type="button" onClick={() => handleClick(1)}>
              <div className="plan">
                <img src="/speed.svg" alt="speed" />
                <p
                  style={
                    plan === 1
                      ? { textDecoration: "underline", color: "green" }
                      : { textDecoration: "none", color: "white" }
                  }
                >
                  Team
                </p>
              </div>
            </button>
            <button type="button" onClick={() => handleClick(2)}>
              <div className="plan">
                <img src="/rombo.svg" alt="rombo" />
                <p
                  style={
                    plan === 2
                      ? { textDecoration: "underline", color: "#FFA800" }
                      : { textDecoration: "none", color: "white" }
                  }
                >
                  Maximizer
                </p>
              </div>
            </button>
          </div>
          {plan === 0 && (
            <div className="plan-details">
              <h4>Starter</h4>
              <ul>
                <li>Recopilación de datos específicos del cliente</li>
                <li>Entrenamiento inicial de la IA</li>
                <li>Configuración de reportes y análisis</li>
                <li>Capacitación y soporte al equipo interno</li>
                <li>Integración con plataformas y sistemas existentes</li>
              </ul>
              <S.Button type="button">Contáctanos</S.Button>
            </div>
          )}
          {plan === 1 && (
            <div className="plan-details" style={{ borderColor: "green" }}>
              <h4>Team</h4>
              <ul>
                <li>Recopilación de datos específicos del cliente</li>
                <li>Entrenamiento inicial de la IA</li>
                <li>Configuración de reportes y análisis</li>
                <li>Capacitación y soporte al equipo interno</li>
                <li>Integración con plataformas y sistemas existentes</li>
              </ul>
              <S.Button type="button">Contáctanos</S.Button>
            </div>
          )}
          {plan === 2 && (
            <div className="plan-details" style={{ borderColor: "#FFA800" }}>
              <h4>Maximizer</h4>
              <div className="double-list">
                <ul>
                  <li>
                    Recopilación de datos específicos del cliente <br />
                    <small>Hasta 2,000 data points</small>
                  </li>
                  <li>
                    Entrenamiento inicial de la IA <br />
                    <small>Hasta 2,000 data points</small>
                  </li>
                  <li>
                    Configuración de reportes y análisis <br />
                    <small>
                      Dashboard avanzado en tiempo real + acceso supervisado
                    </small>
                  </li>
                  <li>
                    Capacitación y soporte al equipo interno <br />
                    <small>Hasta 20 horas en setup</small>
                  </li>
                  <li>
                    Integración con plataformas y sistemas existentes <br />
                    <small>1 CRM, 1 ERP ó API 10 campos</small>
                  </li>
                </ul>
                <ul>
                  <li>Análisis de necesidades y objetivos</li>
                  <li>
                    Creación y personalización del chatbot (look and feel)
                  </li>
                  <li>Pruebas internas y ajustes</li>
                  <li>Lanzamiento y puesta en marcha del chatbot</li>
                  <li>Personalización avanzada de la IA</li>
                </ul>
              </div>
              <S.Button type="button">Contáctanos</S.Button>
            </div>
          )}
        </div>
      </S.Plans>
    </S.Container>
  );
};
export default GyaBot;
