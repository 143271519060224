import axios from "axios";

const url = "https://apichat.therocketcode.mx";
// const url = "http://localhost:5002";

const api = axios.create({
  maxBodyLength: 70 * 1024 * 1024,
  maxContentLength: 70 * 1024 * 1024,
  baseURL: url,
  timeout: 60000,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
