import React from 'react';
import RocketBot from './RocketBot/RocketBot';
import LayoutApp from './RocketBot/LayoutApp';

function Rocketbot() {
  return (
    <div className="App">
      <LayoutApp>
        <RocketBot />
      </LayoutApp>
    </div>
  );
}

export default Rocketbot;
