export const url = 'https://labocatalogue.therocketcode.com';
// export const url = 'http://localhost:3000';

export const fetchHistory = async ({ threadId }) => {
  try {
    const response = await fetch(
      `${url}/api/swarm/history?threadId=${threadId}`
    );
    const data = await response.json();
    return data.data || [];
  } catch (error) {
    console.error('Error al obtener el historial', error);
  }
};

export const fetchThreads = async ({ threadIds }) => {
  try {
    const response = await fetch(`${url}/api/swarm/history/names`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ threadIds }),
    });
    const data = await response.json();
    return data.data || [];
  } catch (error) {
    console.error('Error al obtener los hilos', error);
  }
};

export const renameHistoryChat = async ({ threadId, name }) => {
  try {
    await fetch(
      `${url}/api/swarm/history/rename?threadId=${threadId}&chat_name=${name}`,
      {
        method: 'PATCH',
      }
    );
  } catch (error) {
    console.error('Error al renombrar el chat', error);
  }
};

export const handlerLikeMessaage = async ({
  messages,
  agent_name,
  action,
  threadId,
}) => {
  try {
    await fetch(`${url}/api/swarm/feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages,
        agent_name,
        threadId,
        action,
      }),
    });
  } catch (error) {
    console.error('Error al enviar el like', error);
  }
};

export const stopStreaming = async ({ threadId }) => {
  try {
    await fetch(`${url}/api/swarm/stop/streaming/?threadId=${threadId}`, {
      method: 'POST',
    });
  } catch (error) {
    console.error('Error al detener el streaming', error);
  }
};
