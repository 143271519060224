import api from '../api/index';


export const chatOpenAI = async (message) => {

try {
        const response = await api.post('/chat/inter', { userMessage: message, ambiente:"intermx", idSession: sessionStorage.getItem("idSession")});
        sessionStorage.setItem("idSession", response.data.response.idSession);
        return response.data.response.message;
    } catch (error) {
        console.error(error);
        return 'Hubo un error al procesar tu mensaje. Intenta de nuevo más tarde.'
    }
}