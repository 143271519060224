import React, { useEffect } from 'react';
import { Widget, addResponseMessage } from 'react-chat-widget-2';
import { chatOpenAI } from './services/chatMacma.service'
import 'react-chat-widget-2/lib/styles.css';

import logo from './logo.svg';
import './assets/chat_rocket.css';

function IndexChatCopy() {
  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const iphone = (/iP(hone|od|ad)/.test(userAgent));
    console.log("Iphone", iphone);
    const divChat = document.getElementById("chatbotDiv");
    if(iphone && divChat !== null){
      divChat.classList.add("isIphone");
    } else {
      divChat.classList.remove("isIphone");
    }
  }, []);

  const handleNewUserMessage = async (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    // Now send the message throught the backend API
    const chatOpenAIResponse = await chatOpenAI(newMessage);
    addResponseMessage(chatOpenAIResponse);
  };

  return (
    <div id="chatbotDiv" className="Chat">
      <Widget
        handleNewUserMessage={handleNewUserMessage}
        profileAvatar={logo}
        title="Asistente"
        subtitle="¿En que te puedo ayudar?"
      />
    </div>
  );
}

export default IndexChatCopy;
