import axios from "axios";

// const url = 'https://labocatalogue.therocketcode.com';
const url = "http://localhost:3000";

const api = axios.create({
  maxBodyLength: Infinity,
  maxContentLength: Infinity,
  baseURL: url,
  timeout: 1000000,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;
