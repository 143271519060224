import Logo from './Logo';
import Burger from './Burger';
import styled from 'styled-components';

const IconsmenuWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
const HeaderRoot = styled.div`
  position: sticky;
  top: 0;
  height: 68px;
  width: 390px;
  background-color: #0e1d39;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 16px 24px;
  box-sizing: border-box;
  gap: 0px 10px;
  @media screen and (max-width: 420px) {
    display: flex;
    width: 100%;
    height: auto;
    padding-top: 16px;
    box-sizing: border-box;
  }
`;

const HeaderMobile = ({
  setMessages,
  setThreadId,
  setIsFinished,
  threads,
  setThreads,
}) => {
  return (
    <HeaderRoot>
      <Logo />
      <IconsmenuWrapper>
        <Burger
          setMessages={setMessages}
          setThreadId={setThreadId}
          setIsFinished={setIsFinished}
          threads={threads}
          setThreads={setThreads}
        />
      </IconsmenuWrapper>
    </HeaderRoot>
  );
};

export default HeaderMobile;
