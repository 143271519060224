import React from 'react';
import axios from 'axios';

class Mailroom extends React.Component {
  state = {
    ticketNumber: ''
  };

  handleInputChange = (event) => {
    this.setState({
      ticketNumber: event.target.value
    });
  }

  handleClick = () => {
    const data = {
      userMessage: "style",
      ambiente: "intermx",
      idSession: 1348
    };

    if (this.state.ticketNumber) {
      data.idTicket = this.state.ticketNumber;
    }

    axios.post('https://apichat.therocketcode.mx/mailroom', data)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }

  render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
        <header style={{ marginBottom: '50px' }}></header>
        <input 
          type="text" 
          placeholder="Ticket Number (optional)" 
          value={this.state.ticketNumber} 
          onChange={this.handleInputChange} 
          style={{ marginBottom: '20px', padding: '10px', fontSize: '16px' }}
        />
        <button 
          onClick={this.handleClick}
          style={{
            backgroundColor: '#4CAF50', /* Green */
            border: 'none',
            color: 'white',
            padding: '15px 32px',
            textAlign: 'center',
            textDecoration: 'none',
            display: 'inline-block',
            fontSize: '16px',
            margin: '4px 2px',
            cursor: 'pointer',
          }}
        >
          Send Request
        </button>
        <footer style={{ marginTop: '50px' }}></footer>
      </div>
    );
  }
}

export default Mailroom;