import React from 'react';
import NewieBot from './RocketBot/GyaBot';
import LayoutAppNewie from './RocketBot/LayoutAppGya';

function AppCopy() {
  return (
    <div className="App">
      <LayoutAppNewie>
        <NewieBot />
      </LayoutAppNewie>
    </div>
  );
}

export default AppCopy;
