import { useEffect, useState, useCallback } from 'react';
import Logo from './Logo';
import NewProyectButton from './NewProyectButton';
import styled from 'styled-components';
import {
  fetchHistory,
  fetchThreads,
  renameHistoryChat,
} from '../services/swarm.service';
import DeleteIcon from '@material-ui/icons/Delete';
import { Edit } from '@material-ui/icons';

const Reciente = styled.div`
  position: relative;
  font-size: 14px;
  line-height: 28.49px;
`;
const PropuestaCliente = styled.div`
  flex: 1;
  color: #fff;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background-color: transparent;
  font-size: 16px;
  line-height: 28.49px;
  font-weight: 600;
  cursor: pointer;
`;
const PropuestaClienteWrapper = styled.div`
  display: flex;
  align-self: stretch;
  border-radius: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 14px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  overflow: hidden;

  &:hover {
    background-color: #1d4ed8;
  }
`;
const InputChangeName = styled.input`
  display: none;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;
  font-size: 16px;
  line-height: 28.49px;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
`;
const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 36px;
  box-sizing: border-box;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 80%;

  .delete-icon,
  .edit-icon {
    min-width: 18px;
  }

  .delete-icon {
    cursor: pointer;
    color: #d1d5db;
    transition: all 0.5s ease;

    &:hover {
      color: #c1494a;
    }
  }

  .edit-icon {
    cursor: pointer;
    color: #d1d5db;
    transition: all 0.5s ease;

    &:hover {
      color: #00aa58;
    }
  }
`;

const RecienteParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 24px;
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
const SidebarRoot = styled.div`
  align-self: stretch;
  background-color: #0e1d39;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 32px 24px;
  box-sizing: border-box;
  gap: 24px 0px;
  min-width: 260px;
  max-width: 300px;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: Poppins;
  @media screen and (max-width: 420px) {
    display: none;
    padding-top: 0px;
    padding-bottom: 0px;
    box-sizing: border-box;
  }
`;

const Sidebar = ({
  setMessages,
  setThreadId,
  setIsFinished,
  threads,
  setThreads,
  ...props
}) => {
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const getThreadsFromSessionStorage = async () => {
      let threads = [];
      try {
        const threadIds = JSON.parse(sessionStorage.getItem('history'));
        if (threadIds && threadIds.length > 0) {
          threads = await fetchThreads({ threadIds });
        }
      } catch (error) {
        console.error('Error al obtener los hilos', error);
      }
      return threads;
    };

    getThreadsFromSessionStorage().then((threads) => setThreads(threads));
  }, []);

  const handleChangeConversation = async ({ threadId }) => {
    console.log('threadId', threadId);
    try {
      setIsFetching(true);
      setThreadId(threadId);
      setMessages([]);
      const response = await fetchHistory({ threadId });
      const messagesFomDDBB = response
        .sort((a, b) => new Date(a.CREATED_AT) - new Date(b.CREATED_AT))
        .map((msg) => msg.message);

      const messagesFromThread = [];

      messagesFomDDBB.forEach((msg) => {
        if (msg) {
          const escapedMessage = msg.replace(
            /[\u0000-\u0019]/g,
            (match) => `\\u${match.charCodeAt(0).toString(16).padStart(4, '0')}`
          );
          const messageObject = JSON.parse(escapedMessage);

          if (messageObject && messageObject.message && messageObject.name) {
            const unescapedContent = messageObject.message.replace(
              /\\u([\da-f]{4})/gi,
              (match, grp) => String.fromCharCode(parseInt(grp, 16))
            );
            const unescapedName = messageObject.name.replace(
              /\\u([\da-f]{4})/gi,
              (match, grp) => String.fromCharCode(parseInt(grp, 16))
            );
            messagesFromThread.push({
              message: unescapedContent,
              name: unescapedName,
            });
          }
        }
      });

      setMessages(messagesFromThread);
      setIsFinished(true);
    } catch (error) {
      console.error('Error al obtener el historial', error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleNewProyect = useCallback(() => {
    setThreadId('');
    setMessages([]);
    setIsFinished(false);
  }, []);

  const removeFromSession = ({ threadId }) => {
    const sessionHistory = JSON.parse(sessionStorage.getItem('history'));
    sessionStorage.setItem(
      'history',
      JSON.stringify(sessionHistory.filter((item) => item !== threadId))
    );
    const newHistory = threads.filter((item) => item.threadId !== threadId);
    setThreads(newHistory);
  };

  const renameChat = async ({ threadId, name }) => {
    try {
      if (name) {
        await renameHistoryChat({ threadId, name });
        const newThreads = threads.map((thread) => {
          if (thread.threadId === threadId) {
            return { ...thread, chat_name: name };
          }
          return thread;
        });
        setThreads(newThreads);
      }

      return;
    } catch (error) {
      console.error('Error al renombrar el chat', error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleEditName = ({ threadId }) => {
    const div = document.getElementById(`div-${threadId}`);
    const input = document.getElementById(`input-${threadId}`);
    try {
      div.style.display = 'none';
      input.style.display = 'block';
      input.focus();

      input.addEventListener('blur', () => {
        renameChat({ threadId, name: input.value });
        div.style.display = 'block';
        input.style.display = 'none';
      });
    } catch (error) {
      console.error('Error al renombrar el chat', error);
    }

    return () => {
      div.style.display = 'block';
      input.style.display = 'none';
    };
  };

  return (
    <SidebarRoot>
      <Logo />
      <NewProyectButton onClick={handleNewProyect} />

      {threads.length > 0 && (
        <RecienteParent>
          <Reciente>Reciente</Reciente>
          {threads.map((thread, index) => (
            <PropuestaClienteWrapper key={index}>
              <PropuestaCliente
                onClick={() =>
                  handleChangeConversation({ threadId: thread.threadId })
                }
                disabled
                name={thread.chat_name}
                id={`div-${thread.threadId}`}
                value={thread.chat_name}
              >
                {thread.chat_name}
              </PropuestaCliente>
              <InputChangeName
                type="text"
                name="chat_name"
                id={`input-${thread.threadId}`}
              />
              <IconsWrapper>
                <DeleteIcon
                  className="delete-icon"
                  onClick={() =>
                    removeFromSession({ threadId: thread.threadId })
                  }
                />
                <Edit
                  className="edit-icon"
                  onClick={() => handleEditName({ threadId: thread.threadId })}
                />
              </IconsWrapper>
            </PropuestaClienteWrapper>
          ))}
        </RecienteParent>
      )}
    </SidebarRoot>
  );
};

export default Sidebar;
