import React, { useEffect, useState, useContext, useRef } from "react";
import api from '../api/index';
import APILAB from "../api/configLaboratorio";


export const visionOpenAi = async (question, imageUrl) => {

try {
        const response = await APILAB.get(`/api/openai/vision/create-description?question=${question}&imageUrl=${encodeURIComponent(imageUrl)}`);
        return response.data.description;
    } catch (error) {
       console.log("🚀 ~ file: vision.service.js:11 ~ chatOpenAI ~ error:", error)
        return 'Hubo un error al procesar tu mensaje. Intenta de nuevo más tarde.'
    }
}