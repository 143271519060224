// Importa las bibliotecas necesarias
import React, { useState } from "react";
import styled from "styled-components";
import { visionOpenAi } from "./services/vision.service";
// Estilos utilizando styled-components
const Container = styled.div`
  max-width: 500px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
  font-size: 18px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

// Componente principal
const Vision = () => {
  // Estados para almacenar la pregunta y la URL de la imagen
  const [question, setQuestion] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [description, setDescription] = useState("");

  // Función para manejar el envío del formulario
  const handleSubmit = async (e) => {
    e.preventDefault();
    const responseVision = await visionOpenAi(question, imageUrl);
    setDescription(responseVision);
    console.log("Pregunta:", question);
    console.log("URL de la imagen:", imageUrl);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Input
          type="text"
          placeholder="Escribe tu pregunta"
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
        />
        <Input
          type="text"
          placeholder="URL de la imagen"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
        />
        <Button type="submit">Enviar</Button>
      </form>
      <br />
      <TextArea
        style={{ height: "80vh" }}
        placeholder="Descripción del resultado"
        rows="4"
        value={description}
        disabled
        onChange={(e) => setDescription(e.target.value)}
      />
    </Container>
  );
};

export default Vision;
