import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Swarm from './pages/Swarm';

const App = () => {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    let title = '';
    let metaDescription = '';

    switch (pathname) {
      case '/':
        title = '';
        metaDescription = '';
        break;
      case '/chat':
        title = '';
        metaDescription = '';
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Swarm />} />
    </Routes>
  );
};

export default App;
