import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  gap: 150px;
  h1 {
    font-size: 100px;
    margin: 0;
  }
  h2 {
    font-size: 44px;
    font-weight: 700;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
  }
  h3 {
    font-size: 35px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
  }
  h4 {
    font-size: 28px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
  }
  p {
    margin: 0;
  }
  small {
    color: white;
  }
  span {
    font-size: 20px;
  }
  @media (max-width: 800px) {
    h1 {
      font-size: 55px;
    }
    h2 {
      font-size: 31px;
      line-height: 37px;
    }
    h3 {
      font-size: 25px;
      line-height: 30px;
    }
    h4 {
      font-size: 25px;
      line-height: 30px;
    }
    gap: 80px;
    padding: 30px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  background: #2240d9;
  border-radius: 8px;
  color: white;
  border: 0;
  min-width: 200px;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const Header = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  padding-top: 120px;
  .logo-name {
    display: flex;
    align-items: center;
    gap: 25px;
  }
  h2 {
    color: #ffa800;
  }
  @media (max-width: 800px) {
    h2 {
      text-align: left;
    }
    img {
      max-width: 100px;
    }
    .logo-name {
      flex-direction: column;
    }
  }
`;

export const AiPower = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  .subtitle {
    display: flex;
    align-items: center;
    img {
      max-width: 30px;
      margin-left: 10px;
    }
    span {
      color: white;
      margin: 0 10px 0 5px;
    }
  }
  .bullets-container {
    display: flex;
    align-items: center;
    gap: 35px;
    padding-top: 30px;
  }
  .bullet {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-direction: column;
    max-width: 350px;
    text-align: center;
    transition: all 0.5s;
    img {
      max-width: 170px;
    }
    small {
      opacity: 0;
    }
    &:hover {
      scale: 110%;
      small {
        opacity: 1;
      }
    }
  }
  @media (max-width: 800px) {
    .subtitle {
      p,
      span {
        font-size: 12px;
        margin: 0 5px 0 5px;
      }
      img {
        max-width: 25px;
        margin-left: 5px;
      }
    }
    .bullets-container {
      flex-direction: column;
    }
    .bullet {
      small {
        opacity: 1;
      }
      &:hover {
        scale: 100%;
      }
    }
  }
`;

export const HowItWorks = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  .bullet {
    display: grid;
    grid-template-columns: 1fr 10px 1fr;
    width: 100%;
    gap: 80px;
    padding-bottom: 30px;
    .gpt {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      p {
        font-weight: 700;
      }
    }
    ul {
      /* width: 260px; */
      list-style-type: disc;
    }
    li {
      color: white;
      padding: 2px 0;
    }
    span {
      color: white;
      max-width: 380px;
    }
    .division {
      height: 200px;
      width: 3px;
      background: white;
    }
    .list {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  @media (max-width: 800px) {
    .bullet {
      gap: 30px;
      img {
        max-width: 80px;
      }
      p {
        text-align: center;
      }
    }
  }
`;

export const Plans = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-bottom: 80px;
  .plans-container {
    display: flex;
    align-items: center;
    gap: 80px;
    padding-top: 80px;
  }
  .plans {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
    border: 1px blue solid;
    padding: 40px 30px 40px 15px;
    height: 550px;
    button {
      all: unset;
      cursor: pointer;
    }
  }
  .plan {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .plan-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
    border: 1px red solid;
    padding: 40px;
    height: 550px;
    width: 750px;
    small {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: -0.01em;
      text-align: left;
    }
    .double-list {
      display: flex;
      align-items: center;
      gap: 30px;
      ul {
        max-width: 320px;
      }
    }
    ul {
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    ul li {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 1em;
      list-style-type: none;
      padding: 0.25em 0 0 2.5em;
      position: relative;
      color: #fff;
      &:after {
        content: " ";
        display: block;
        width: 0.3em;
        height: 0.6em;
        border: solid #fff;
        border-width: 0 0.2em 0.2em 0;
        position: absolute;
        left: 1em;
        top: 40%;
        margin-top: -0.2em;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }
  @media (max-width: 800px) {
    p {
      text-align: center;
    }
    .plans-container {
      flex-direction: column;
      gap: 30px;
      padding: 20px 0 0;
    }
    .plans {
      flex-direction: row;
      height: auto;
      padding: 20px;
      width: 100%;
      box-sizing: border-box;
      img {
        max-width: 60px;
      }
    }
    .plan {
      flex-direction: column;
      gap: 0;
      justify-content: space-between;
      height: 100px;
    }
    .plan-details {
      width: auto;
      height: auto;
      padding: 30px;
      .double-list {
        flex-direction: column;
        gap: 100px;
      }
    }
  }
`;
