import { useState, useCallback } from 'react';
import MenuOpen from './MenuOpen';
import PortalDrawer from './PortalDrawer';
import styled from 'styled-components';

const BurgerIcon = styled.img`
  position: absolute;
  height: 56.39%;
  width: 72.86%;
  top: 21.94%;
  right: 13.71%;
  bottom: 21.67%;
  left: 13.43%;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
`;
const IconsmenuRoot = styled.button`
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
  width: 35px;
  position: relative;
  height: 36px;
`;

const Burger = ({
  setMessages,
  setThreadId,
  setIsFinished,
  threads,
  setThreads,
}) => {
  const [isMenuOpenOpen, setMenuOpenOpen] = useState(false);

  const openMenuOpen = useCallback(() => {
    setMenuOpenOpen(true);
  }, []);

  const closeMenuOpen = useCallback(() => {
    setMenuOpenOpen(false);
  }, []);

  return (
    <>
      <IconsmenuRoot onClick={openMenuOpen}>
        <BurgerIcon alt="" src="/burger.svg" />
      </IconsmenuRoot>
      {isMenuOpenOpen && (
        <PortalDrawer
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Left"
          onOutsideClick={closeMenuOpen}
        >
          <MenuOpen
            onClose={closeMenuOpen}
            setMessages={setMessages}
            setThreadId={setThreadId}
            setIsFinished={setIsFinished}
            threads={threads}
            setThreads={setThreads}
          />
        </PortalDrawer>
      )}
    </>
  );
};

export default Burger;
