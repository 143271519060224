import api from "../api/index";
import APILAB from "../api/configLaboratorio";

export const chatDocs = async (question, filename) => {
  try {
    const response = await api.get(
      `/documents/get-information-pdf?question=${question}&filename=${filename}`
    );

    return response.data;
  } catch (error) {
    console.error(error);
    return "Hubo un error al procesar tu mensaje. Intenta de nuevo más tarde.";
  }
};

export const accountStatement = async (filename) => {
  try {
    const response = await APILAB.get(
      `/api/langchain/pdf/question/download?key=${filename}`,
      {
        responseType: "arraybuffer",
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return "Hubo un error al procesar tu mensaje. Intenta de nuevo más tarde.";
  }
};

export const questionPDF = async (question, keyFileName) => {
  try {
    const response = await APILAB.post(`/api/langchain/pdf/question`, {
      path: keyFileName,
      userPrompt: question,
      isLab: true,
      simpleQuestion: true,
    });
    if (response.data.response.length > 0) {
      return response.data.response.join("\n");
    }
    return null;
  } catch (error) {
    console.error(error);
    return "Hubo un error al procesar tu mensaje. Intenta de nuevo más tarde.";
  }
};
