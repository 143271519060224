import styled from 'styled-components';

const PlaceholderIcon = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
`;
const Text1 = styled.div`
  position: relative;
  line-height: 24px;
  font-weight: 600;
`;
const TextPadding = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 2px;
`;
const PlusIcon = styled.img`
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
`;
const MainConteiner = styled.div`
  align-self: stretch;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid #d0d5dd;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  gap: 0px 6px;
  text-align: left;
  font-size: 16px;
  color: #344054;
  font-family: Inter;
  cursor: pointer;
  transition: all 0.5s ease;

  &:hover {
    color: #fff;
    background-color: #2240d9;
  }
`;

const NewProyectButton = ({ ...props }) => {
  return (
    <MainConteiner {...props}>
      <PlaceholderIcon alt="" src="/placeholder.svg" />
      <TextPadding>
        <Text1>Nuevo proyecto</Text1>
      </TextPadding>
      <PlusIcon alt="" src="/plus.svg" />
    </MainConteiner>
  );
};

export default NewProyectButton;
