import api from "../api/index";
import APILAB from "../api/configLaboratorio";

export const uploadFiles = async (formData) => {
  try {
    const response = await api.post("/aws/s3/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    console.log(
      "🚀 ~ file: aws-s3.service.js:9 ~ UploadFile ~ response.data:",
      response.data
    );
    return response.data;
  } catch (error) {
    console.error(error);
    return "Hubo un error al procesar tu mensaje. Intenta de nuevo más tarde.";
  }
};

export const getFiles = async () => {
  try {
    const response = await api.get("/aws/s3/documents");
    console.log(
      "🚀 ~ file: aws-s3.service.js:19 ~ getFiles ~ response:",
      response
    );

    return response.data;
  } catch (error) {
    console.error(error);
    return "Hubo un error al procesar tu mensaje. Intenta de nuevo más tarde.";
  }
};

export const uploadFilesLab = async (file) => {
  try {
    const response = await APILAB.post("/api/aws/s3/upload", {
      file,
      isLab: true,
    });
    return response.data;
  } catch (error) {
    console.log("🚀 ~ uploadFilesLab ~ error:", error);
  }
};

export const getAllFilesLab = async () => {
  try {
    const response = await APILAB.post("/api/aws/s3/all", {
      isLab: true,
    });
    return response.data;
  } catch (error) {
    console.log("🚀 ~ getFilesLab ~ error:", error);
  }
};
