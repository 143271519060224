import { useState } from 'react';
import styled from 'styled-components';
import { url } from '../services/swarm.service';
import { jsPDF } from 'jspdf';
import markdownit from 'markdown-it';
import html2canvas from 'html2canvas';

const PlaceholderIcon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
`;
const Text1 = styled.div`
  position: relative;
  line-height: 28px;
  font-weight: 600;
`;
const TextPadding = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 2px;
`;
const Download01Icon = styled.img`
  width: 24px;
  position: relative;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
`;
const Container = styled.button`
  border-radius: 8px;
  background-color: #2240d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border: 1px solid #2240d9;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 16px 22px;
  gap: 0px 10px;
  text-align: left;
  font-size: 18px;
  color: #fff;
  font-family: Inter;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #1d2f7b;
    border-color: #1d2f7b;
  }

  &:disabled {
    background-color: #d6e0ef;
    color: #98a2b3;
    cursor: not-allowed;
  }
`;

const DownloadButton = ({ threadId }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      const apiURL = `${url}/api/swarm/download/pdf?threadId=${threadId}`;
      const response = await fetch(apiURL);
      if (!response.ok) {
        throw new Error('Error al descargar el archivo');
      }

      const blob = await response.blob();
      const urlBlob = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = urlBlob;
      link.download = `swarm_pres_${threadId}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <Container
      onClick={handleDownload}
      disabled={isDownloading}
    >
      <PlaceholderIcon alt="" src="/placeholder.svg" />
      <TextPadding>
        <Text1>
          {isDownloading ? 'Desscargando...' : 'Descargar propuesta'}
        </Text1>
      </TextPadding>
      <Download01Icon alt="" src="/download01.svg" />
    </Container>
  );
};

export default DownloadButton;
