// import logo from './logo.svg';
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import {
  uploadFiles,
  getFiles,
  uploadFilesLab,
  getAllFilesLab,
} from "./services/aws-s3.service";
import { chatDocs, questionPDF } from "./services/langchain-docs.service";
// import { api, apiBack } from '../api'
const Container = styled.div`
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 100%;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  height: 50px;
  font-size: 18px;
  margin-bottom: 10px;
  box-sizing: border-box;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
`;

const initalColumns = [
  { field: "id", headerName: "ID", width: 50 },
  { field: "name", headerName: "Nombre", width: 130 },
  { field: "url", headerName: "Link", width: 400 },
];
const contentStyles = {
  width: "100vw",
  height: "100vh",
  backgroundColor: "#f0f0f0",
};
function GetInfoPdf() {
  const [columns] = useState(initalColumns);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [rowSelected, setRowSelected] = useState([]);
  const [question, setQuestion] = useState("");
  const [description, setDescription] = useState("");
  const [pdfBase64, setPdfBase64] = useState("");

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file.type === "application/pdf") {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(",")[1];
        setPdfBase64(base64Data);
      };
      reader.readAsDataURL(file);
    } else {
      alert("El archivo seleccionado no es un PDF.");
    }
  };
  const uploadFile = async () => {
    if (pdfBase64) {
      try {
        const inputFile = {
          base64: pdfBase64,
          name: selectedFile.name,
          mimetype: selectedFile.type,
        };
        const file = await uploadFilesLab(inputFile);
        if (file.statusCode === 200) {
          await getAllFiles();
        }
      } catch (error) {
        console.error("Error al subir el archivo:", error.message);
      }
    } else {
      console.log("No se ha seleccionado ningún archivo.");
    }
  };

  const handleQuestion = async () => {
    if (rowSelected.length === 0) {
      alert("Selecciona un archivo de la tabla");
      return;
    }
    if (question === "") {
      alert("Escribe una pregunta");
      return;
    }
    const filename = files.find((file) => file.id === rowSelected[0])?.name;
    const response = await questionPDF(question, filename);
    setDescription(response || "No se encontró respuesta");
  };

  useEffect(() => {
    getAllFiles();
  }, []);

  const getAllFiles = async () => {
    const allFiles = await getAllFilesLab();

    const result = [];
    allFiles.forEach((file, index) => {
      const row = {
        id: index + 1,
        name: file.path,
        url: file.url,
      };
      result.push(row);
    });
    setFiles(result);
  };

  return (
    <div style={contentStyles}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <div style={{ width: "50%" }}>
          <DataGrid
            style={{ height: 700, width: "100%" }}
            title="Archivos"
            rows={files}
            columns={columns}
            onRowSelectionModelChange={(row) => {
              setRowSelected(row);
            }}
            rowSelectionModel={rowSelected}
          />
          <div style={{ textAlign: "center", marginTop: "10px" }}>
            <input type="file" onChange={(e) => handleChange(e)} />
            <input type="button" value="Subir archivo" onClick={uploadFile} />
          </div>
        </div>
        <div style={{ width: "50%" }}>
          <Container>
            <Input
              type="text"
              placeholder="Escribe tu pregunta"
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            <Button type="button" onClick={() => handleQuestion()}>
              Enviar
            </Button>
            <br />
            <TextArea
              style={{ fontSize: "20px", marginTop: "10px", height: "80vh" }}
              placeholder="Descripción del resultado"
              rows="4"
              value={description}
              disabled
              onChange={(e) => setDescription(e.target.value)}
            />
          </Container>
        </div>
      </div>
    </div>
  );
}

export default GetInfoPdf;
