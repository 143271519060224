import React from 'react';
import MacmaBot from './RocketBot/MacmaBot';
import LayoutAppMacma from './RocketBot/LayoutAppMacma';

function AppCopy() {
  return (
    <div className="App">
      <LayoutAppMacma>
        <MacmaBot />
      </LayoutAppMacma>
    </div>
  );
}

export default AppCopy;
