// import logo from './logo.svg';
import React, { useState, useRef } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { uploadFilesLab } from "./services/aws-s3.service";
import { accountStatement } from "./services/langchain-docs.service";
import CircularProgress from "@material-ui/core/CircularProgress";

const StyledFileInput = styled.input`
  display: none;
`;

const StyledButton = styled.label`
  background-color: #4caf50;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block; /* Añadido para que el clic funcione correctamente */
`;
const StyledButtonSend = styled.label`
  background-color: #05476b;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block; /* Añadido para que el clic funcione correctamente */
`;
const contentStyles = {
  width: "100vw",
  height: "100vh",
  backgroundColor: "#f0f0f0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const successStyle = {
  color: "green",
  fontWeight: "bold",
  fontSize: "20px",
};

function AcccountStatements() {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [pdfBase64, setPdfBase64] = useState("");
  const fileInputRef = useRef(null);
  const [messageResult, setMessageResult] = useState("");

  const handleChange = (event) => {
    setMessageResult("");
    const file = event.target.files[0];
    if (file.type === "application/pdf") {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result.split(",")[1];
        setPdfBase64(base64Data);
      };
      reader.readAsDataURL(file);
    } else {
      alert("El archivo seleccionado no es un PDF.");
    }
  };
  const uploadFile = async () => {
    if (pdfBase64) {
      try {
        setIsLoading(true);
        const inputFile = {
          base64: pdfBase64,
          name: selectedFile.name,
          mimetype: selectedFile.type,
        };
        console.log("🚀 ~ uploadFile ~ inputFile:", inputFile);
        const file = await uploadFilesLab(inputFile);
        console.log("🚀 ~ uploadFile ~ file:", file);
        if (file.message === "the file already exists") {
          console.log("entro if");

          const buffer = await accountStatement(selectedFile.name);

          const file = new File([buffer], "account-statement.xlsx");
          const downloadUrl = window.URL.createObjectURL(file);
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = file.name;
          a.click();
          setMessageResult("Proceso concluido con éxito.");
        } else {
          console.log("entro else", file.key);
          const buffer = await accountStatement(file.key);

          const file = new File([buffer], "account-statement.xlsx");
          const downloadUrl = window.URL.createObjectURL(file);
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.download = file.name;
          a.click();
          setMessageResult("Proceso concluido con éxito.");
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error al subir el archivo:", error.message);
      }
    } else {
      console.log("No se ha seleccionado ningún archivo.");
    }
  };
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div style={contentStyles}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        // <div style={{ textAlign: "center", marginTop: "10px" }}>
        //   <input type="file" onChange={(e) => handleChange(e)} />
        //   <input type="button" value="Subir archivo" onClick={uploadFile} />
        // </div>
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <StyledFileInput
            type="file"
            ref={fileInputRef}
            onChange={(e) => handleChange(e)}
          />
          <StyledButton onClick={handleButtonClick}>
            Seleccionar Archivo
          </StyledButton>
          <StyledButtonSend onClick={uploadFile}>
            Subir archivo
          </StyledButtonSend>
          <div style={successStyle}>{messageResult}</div>
        </div>
      )}
    </div>
  );
}

export default AcccountStatements;
