import styled from "styled-components";
// import { NavLink as Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { device } from "./style/device";
import { isMobile } from "react-device-detect";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./style/footer.css";
import { useTranslation } from "react-i18next";

const FooterStyles = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 20px;
  .MuiSvgIcon-root {
    color: white !important;
  }
  h5 {
    text-align: center;
    margin: 0;
    padding: 0;
    font-weight: bold;
    font-size: 18px;
  }
  li {
    padding: 5px 0;
  }
  .site {
    width: 90%;
  }
  .contact-us {
    a {
      text-decoration: underline;
    }
  }

  @media ${device.tablet} {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
    align-content: center;
    width: 100%;
    padding: 8% 8% 5%;
    box-sizing: border-box;
    h3 {
      display: block;
      margin-top: 0;
      font-size: 32px;
    }
    h5 {
      text-align: left;
      margin: 0;
    }
    .title {
      padding-bottom: 30px;
      letter-spacing: 2px;
    }
    form {
      display: block;
    }
    img {
      display: inline;
      width: 80%;
    }
    ul {
      padding: 0;
      margin: 0;
    }
    li {
      font-weight: 400;
      font-size: 20px;
      line-height: 41.38px;
      color: white;
      list-style-type: none;
    }
    input {
      width: 100%;
      background-color: transparent;
      background: url("./Group 222.png") no-repeat;
      background-position: 100%;
      border: none;
      opacity: 0.3;
      outline: none;
      color: #fff;
      font-size: 20px;
      line-height: 45px;
      border-bottom: 1px solid #fff;
    }
    .site {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-content: space-between;
      width: 100%;
      padding-bottom: 20px;
      p {
        line-height: 25px;
      }
    }

    .contact-us {
      display: flex;
    }

    .newsletter {
      position: absolute;
      right: 8%;
      bottom: 10%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 353px;
      img {
        width: 162px;
      }
    }
  }
`;

function Footer() {
  const ScrollToHowWeWork = () => {
    setTimeout(() => {
      document.getElementById("scroll-link").click();
    }, 200);
  };

  const { t } = useTranslation(["footer"]);

  return (
    <>
      {isMobile ? (
        <FooterStyles>
          <div className="site">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <h5 className="title">{t("c1.title")}</h5>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <ul>
                    <li style={{ fontSize: "14px" }}>
                      <a href="/" onClick={ScrollToHowWeWork}>
                        {t("c1.l1")}
                      </a>
                    </li>
                    <li style={{ display: "none" }}>
                      <ScrollLink
                        to="HowWeWork"
                        id="scroll-link"
                        smooth={true}
                        duration={1000}
                        spy={true}
                        offset={-160}
                      >
                        {t("c1.l1")}
                      </ScrollLink>
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      <a href="/hiring">{t("c1.l2")}</a>
                    </li>
                    <li style={{ fontSize: "14px" }}>
                      <a href="/case-studies">{t("c1.l3")}</a>
                    </li>
                  </ul>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <h5 className="title">{t("c2.title")}</h5>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "20px",
                    }}
                  >
                    <img
                      src="/location.svg"
                      alt="Location"
                      style={{
                        height: "24px",
                        width: "24px",
                        paddingRight: "5px",
                      }}
                    />
                    <h5 style={{ fontSize: "16px" }}>México City</h5>
                  </div>
                  <p style={{ fontSize: "14px" }}>
                    Anillo Perif. Sur 3720 <br />
                    Jardines del Pedregal <br />
                    Álvaro Obregón <br />
                    01900 México City, México
                  </p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="/location.svg"
                      alt="Location"
                      style={{
                        height: "24px",
                        width: "24px",
                        paddingRight: "5px",
                      }}
                    />
                    <h5 style={{ fontSize: "16px" }}>Querétaro, México</h5>
                  </div>
                  <p style={{ fontSize: "14px" }}>
                    Av. Paseo Monte Miranda 15 <br />
                    Int. 812 <br />
                    Colonia Miranda <br />
                    76240 El Marques, Querétaro.
                  </p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src="/location.svg"
                      alt="Location"
                      style={{
                        height: "24px",
                        width: "24px",
                        paddingRight: "5px",
                      }}
                    />
                    <h5 style={{ fontSize: "16px" }}>Miami</h5>
                  </div>
                  <p style={{ fontSize: "14px" }}>
                    1200 Ponce de Leon, Coral Gables, <br />
                    Miami, FL 33134 <br />
                    USA
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <h5 className="title">{t("c3.title")}</h5>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div style={{ alignItems: "center" }} className="contact-us">
                    <ul>
                      <li>
                        <a
                          style={{
                            textDecoration: "underline",
                            fontSize: "14px",
                          }}
                          href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@therocketcode.com"
                          target="blank"
                        >
                          contact@therocketcode.com
                        </a>
                      </li>
                      <li>
                        <p style={{ fontSize: "14px", margin: "5px 0px" }}>
                          {t("c3.l1")}
                          <br />
                          <a
                            href="https://wa.me/+5215580833336"
                            target="_blank"
                            rel="noreferrer"
                          >
                            +52 1 55 8083 3336
                          </a>
                        </p>
                      </li>
                      <li>
                        <p style={{ fontSize: "14px", margin: "5px 0px" }}>
                          {t("c3.l2")} <br />
                          <a
                            href="https://wa.me/+5215580833336"
                            target="_blank"
                            rel="noreferrer"
                          >
                            55 8083 3336
                          </a>
                        </p>
                      </li>
                    </ul>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              backgroundColor: "rgba(0,0,0,0.4)",
              padding: "20px 0",
              margin: "20px 0",
            }}
          >
            <a
              href="https://www.linkedin.com/company/the-rocket-code/about/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                style={{ height: "37px" }}
                src="/Linkedin-mobile.svg"
                alt="Linkedin"
              />
            </a>
            <a
              href="https://www.instagram.com/the.rocket.code"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                style={{ height: "37px" }}
                src="/Instagram-mobile.svg"
                alt="Instagram"
              />
            </a>
            <a
              href="https://www.facebook.com/therocketcode"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                style={{ height: "37px" }}
                src="/Facebook-mobile.svg"
                alt="Facebook"
              />
            </a>
          </div>
          <div className="newsletter">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "95%",
                padding: "15px",
              }}
            >
              <img src="/LogoRocket.svg" alt="Logo Rocket Code" />
              <a href="https://therocketcode.com/privacy">
                | {t("copyright.pp")}
              </a>
            </div>
            <div>
              <p
                style={{
                  fontSize: "15px",
                  opacity: "0.5",
                  textAlign: "center",
                }}
              >
                © Copyright {new Date().getFullYear()} Rocket Code.{" "}
                {t("copyright.copyright")}
              </p>
            </div>
          </div>
        </FooterStyles>
      ) : (
        <FooterStyles>
          <div className="site">
            <div>
              <h5 style={{ paddingBottom: "30px", letterSpacing: "2px" }}>
                ROCKET CODE
              </h5>
              <ul>
                <li>
                  <a href="/" onClick={ScrollToHowWeWork}>
                    ¿Cómo trabajamos?
                  </a>
                </li>
                <li style={{ display: "none" }}>
                  <ScrollLink
                    to="HowWeWork"
                    id="scroll-link"
                    smooth={true}
                    duration={1000}
                    spy={true}
                    offset={-150}
                  >
                    ¿Cómo trabajamos?
                  </ScrollLink>
                </li>
                <li>
                  <a href="/hiring">Trabaja en Rocket Code</a>
                </li>
                <li>
                  <a href="/case-studies">Casos de estudio</a>
                </li>
              </ul>
            </div>
            <div>
              <h5 style={{ paddingBottom: "30px", letterSpacing: "2px" }}>
                UBICACIONES
              </h5>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/location.svg"
                  alt="Location"
                  style={{ height: "24px", width: "24px", paddingRight: "5px" }}
                />
                <h5>México City</h5>
              </div>
              <p>
                Anillo Perif. Sur 3720 <br />
                Jardines del Pedregal <br />
                Álvaro Obregón <br />
                01900 México City, México
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/location.svg"
                  alt="Location"
                  style={{ height: "24px", width: "24px", paddingRight: "5px" }}
                />
                <h5>Querétaro, México</h5>
              </div>
              <p>
                Av. Paseo Monte Miranda 15 <br />
                Int. 812 <br />
                Colonia Miranda <br />
                76240 El Marques, Querétaro.
              </p>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src="/location.svg"
                  alt="Location"
                  style={{ height: "24px", width: "24px", paddingRight: "5px" }}
                />
                <h5>Miami</h5>
              </div>
              <p>
                1200 Ponce de Leon, Coral Gables, <br />
                Miami, FL 33134 <br />
                USA
              </p>
            </div>
            <div>
              <h5 style={{ paddingBottom: "30px", letterSpacing: "2px" }}>
                CONTACTO
              </h5>
              <div style={{ alignItems: "center" }} className="contact-us">
                <ul>
                  <li>
                    <a
                      style={{ textDecoration: "underline" }}
                      href="https://mail.google.com/mail/?view=cm&fs=1&to=contact@therocketcode.com"
                      target="blank"
                    >
                      contact@therocketcode.com
                    </a>
                  </li>
                  <li>
                    <p>
                      Desde cualquier parte del mundo:
                      <br />
                      <a
                        href="https://wa.me/+5215580833336"
                        target="_blank"
                        rel="noreferrer"
                      >
                        +52 1 55 8083 3336
                       </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      Desde México:
                      <br />
                      <a
                        href="https://wa.me/+5215580833336"
                        target="_blank"
                        rel="noreferrer"
                      >
                        55 8083 3336
                      </a>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <h5 style={{ paddingBottom: "30px", letterSpacing: "2px" }}>
                SÍGUENOS
              </h5>
              <div style={{ display: "flex" }}>
                <a
                  href="https://www.facebook.com/therocketcode"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ height: "37px", paddingRight: "30px" }}
                    src="/Facebook.svg"
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://www.instagram.com/the.rocket.code"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ height: "37px", paddingRight: "30px" }}
                    src="/Instagram.svg"
                    alt="Instagram"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/the-rocket-code/about/?viewAsMember=true"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{ height: "37px", paddingRight: "30px" }}
                    src="/Linkedin.svg"
                    alt="Linkedin"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="newsletter">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "95%",
                padding: "15px",
              }}
            >
              <img src="/LogoRocket.svg" alt="Logo Rocket Code" />
              <a href="https://therocketcode.com/privacy">
                | Política de Privacidad
              </a>
            </div>
            <div>
              <p style={{ fontSize: "15px", opacity: "0.5" }}>
                © Copyright {new Date().getFullYear()} Rocket Code.{" "}
                Derechos reservados.
              </p>
            </div>
          </div>
        </FooterStyles>
      )}
    </>
  );
}

export default Footer;
