import React from 'react';
import InterBot from './RocketBot/InterBot';
import LayoutAppInter from './RocketBot/LayoutAppInter';

function AppCopy() {
  return (
    <div className="App">
      <LayoutAppInter>
        <InterBot />
      </LayoutAppInter>
    </div>
  );
}

export default AppCopy;
