import styled from 'styled-components';

const GoodDesignIs = styled.div`
  align-self: stretch;
  height: 53px;
  position: relative;
  line-height: 120%;
  font-weight: 500;
  display: inline-block;
  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
`;
const ObtnUnaPropuesta = styled.span``;
const Minutos = styled.span`
  color: #ffa800;
`;
const GoodDesignIsContainer = styled.b`
  align-self: stretch;
  position: relative;
  font-size: 20px;
  line-height: 120%;
  font-family: 'DM Sans';
  color: #fff;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;
const SubheaderRoot = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px 0px;
  text-align: center;
  font-size: 44px;
  color: #ffa800;
  font-family: 'Red Hat Text';
`;

const Subheader = () => {
  return (
    <SubheaderRoot>
      <GoodDesignIs>Transforma ideas en propuestas imparables</GoodDesignIs>
      <GoodDesignIsContainer>
        <ObtnUnaPropuesta>{`Obtén una propuesta comercial completa en cuestión de `}</ObtnUnaPropuesta>
        <Minutos>minutos</Minutos>
      </GoodDesignIsContainer>
    </SubheaderRoot>
  );
};

export default Subheader;
