import { useRef, useEffect } from 'react';
import { url } from '../services/swarm.service';
import styled from 'styled-components';
import SendButton from './SendButton';

const FormDesktopRoot = styled.form`
  position: sticky;
  bottom: 0;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 0px 12px;
  min-width: 300px;
  margin: 0;
  padding: 16px 0px 32px 0px;
  background-color: #fff;
  @media screen and (max-width: 420px) {
    display: flex;
  }
`;

const InputRoot = styled.textarea`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 16px 32px;
  min-height: 1rem;
  max-height: 14rem;
  border: 1px solid #2240d9;
  background-color: transparent;
  border-radius: 8px;
  font-family: 'DM Sans';
  font-size: 16px;
  color: #003047;
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    width: 8px;
    height: 24px;
    background: #888;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* Estilos para Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
`;

const Form = ({
  isLoading,
  setIsLoading,
  threadId,
  setThreadId,
  isFinished,
  setIsFinished,
  setMessages,
  setThreads,
}) => {
  const eventSourceRef = useRef();
  const textareaRef = useRef();

  useEffect(() => {
    const textarea = textareaRef.current;
    const resizeTextarea = () => {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    };

    textarea.addEventListener('input', resizeTextarea);
    resizeTextarea();
    return () => {
      textarea.removeEventListener('input', resizeTextarea);
    };
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData(event.target);
    const task = formData.get('prompt');
    if (!task || task === '') {
      setIsLoading(false);
      return;
    }

    if (isLoading) {
      setIsLoading(false);
      eventSourceRef.current.close();
      stopStreaming({ threadId });
      setThreadId('');
      return;
    }

    const eventSource = new EventSource(
      `${url}/api/swarm/conversation/streaming?task=${task}&threadId=${threadId}`
    );

    event.target.reset();
    textareaRef.current.style.height = 'auto';

    eventSourceRef.current = eventSource;

    let timer = setTimeout(() => {
      console.error('Timeout: No se han recibido datos durante 30 segundos');
      eventSource.close();
      setIsLoading(false);
    }, 600000);

    eventSource.addEventListener('threadId', (event) => {
      const data = JSON.parse(event.data);
      const history = JSON.parse(sessionStorage.getItem('history')) || [];
      sessionStorage.setItem(
        'history',
        JSON.stringify([...history, data.threadId])
      );
      setThreadId(data.threadId);
      setThreads((prevThreads) => [
        { threadId: data.threadId, chat_name: 'Propuesta Cliente' },
        ...prevThreads,
      ]);

      clearTimeout(timer);
      timer = setTimeout(() => {
        console.error('Timeout: No se han recibido datos durante 30 segundos');
        eventSource.close();
        setIsLoading(false);
      }, 600000);
    });

    eventSource.onmessage = function (event) {
      const data = JSON.parse(event.data);
      setMessages((prevMessages) => [...prevMessages, data]);

      clearTimeout(timer);
      timer = setTimeout(() => {
        console.error('Timeout: No se han recibido datos durante 30 segundos');
        eventSource.close();
        setIsLoading(false);
      }, 600000);
    };

    eventSource.onerror = function (error) {
      console.error('Error al recibir eventos', error);
      eventSource.close();
      setIsLoading(false);
      setIsFinished(true);
    };

    eventSource.addEventListener('end', (event) => {
      const data = JSON.parse(event.data);
      console.log(data);
      setIsLoading(false);
      setIsFinished(true);
      eventSource.close();

      clearTimeout(timer);
    });

    return () => {
      setIsLoading(false);
      setIsFinished(true);
      eventSource.close();
      clearTimeout(timer);
    };
  };

  return (
    <FormDesktopRoot onSubmit={handleSubmit}>
      <InputRoot
        ref={textareaRef}
        disabled={isLoading || isFinished}
        placeholder="Escribe aquí..."
        id="prompt"
        name="prompt"
      />
      <SendButton disabled={isLoading || isFinished}>Enviar</SendButton>
    </FormDesktopRoot>
  );
};

export default Form;
