// import { NavLink as Link } from "react-router-dom";
import styled from "styled-components";
import { device } from "./device";
// import { FaBars } from "react-icons/fa";

export const Nav = styled.nav`
  background: #0e1d39;
  width: 100vw;
  height: 80px;
  display: flex;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 20px;
  z-index: 10;
  .final__dropdown {
    position: relative;
    z-index: 10;
  }
  .final__dropdown__hover {
    display: flex;
    align-items: center;
    padding: 15px 0;
    background-color: rgba(0,0,0,0.0);
    color: #ffffff;
    border: 0 solid #323b40;
    cursor: pointer;
  }
  .final__dropdown__hover:before {
    content: url("./mundo.svg");
    padding: 10px;
  }
  .final__dropdown__hover:after {
    content: url("./menu-hamburguesa.svg");
    transform: rotate(${props => props.langOpen ? "0deg" : "0deg"});
    padding: 10px;
  }
  .final__dropdown__menu {
    position: absolute;
    flex-direction: column;
    align-items: center;
    top: 100%;
    left: 0;
    min-width: 145px;
    background-color: #ffffff;
    color: #323b40;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
  }
  .final__dropdown .final__dropdown__menu {
    display: ${props => props.langOpen ? "flex" : "none"};
  }
  .final__dropdown__menu p {
    font-size: 14px;
    display: block;
    padding: 10px;
    border-width: 0;
    color: black;
    margin: 0;
  }
  .final__dropdown__menu p:hover {
    color: blue;
    cursor: pointer;
  }
  .lang-opacity {
    display: ${props => props.langOpen ? "flex" : "none"};
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
  }
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  .modal-lang {
    display: ${props => props.langOpen ? "flex" : "none"};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 200px;
    margin: -126px 0 0 -100px;
    width: 100%;
    max-width: 200px;
    background-color: white;
    height: 126px;
    hr {
      color: #9A9A9A;
      border: none;
      background-color: #9A9A9A;
      height: 1px;
      width: 80%;
      margin: 0;
    }
    p {
      color: black;
    }
    .lang-mobile {
      display:flex ;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
    }
  }
  .modal-lang :before {
    content: "";
    display: block;
    height: 126px;
    width: 100%;
    max-width: 200px;
    border: 2px solid white;
    position: absolute;
    bottom: -16px;
    left: -13px;
    z-index: -1;
  }
  @media ${device.tablet} {
    background: #0e1d39;
    width: 100vw;
    height: 80px;
    display: flex;
    position: fixed;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 60px 80px;
    z-index: 10;
    .final__dropdown__hover:after {
    content: url("./flecha-abajo.svg");
    transform: rotate(${props => props.langOpen ? "180deg" : "0deg"});
    padding: 10px;
    }
    .final__dropdown__hover:hover::after {
      content: url("./flecha-arriba.svg");
      transform: rotate(${props => props.langOpen ? "180deg" : "0deg"});
    }
    .final__dropdown__hover:hover::before {
      content: url("./mundo2.svg");
    }
    .final__dropdown__hover:hover {
      color: #FFA800;
    }
  }
`;

export const NavLink = styled.a `
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  &.active {
    color: #002fff;
  }
  & .logo-nav {
    width: 135px;
  }
  @media ${device.tablet} {
    & .logo-nav {
      width: 209px;
    }
  }
`;

export const NavA = styled.a`
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  img {
    width: 209px;
  }
  &.active {
    color: #002fff;
  }
`;

// export const Bars = styled(FaBars)`
//   display: none;
//   color: #fff;
//   @media screen and (max-width: 900px) {
//     display: block;
//     position: absolute;
//     top: 0;
//     right: 0;
//     transform: translate(-100%, 75%);
//     font-size: 1.8rem;
//     cursor: pointer;
//   }
// `;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;
  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  display: none;
  /* align-items: center;
  margin-right: 15px; */
  @media ${device.tablet} {
    display: flex;
    align-items: center;
    margin-right: 24px;
  }
`;

export const NavBtnLink = styled.a `
  padding: 10px;
  min-width: 75px;
  text-align: center;
  border: 2px solid white;
  color: white;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-size: 12px;
  font-weight: 700;
  margin-left: 0px;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #010606;
  }
  @media ${device.tablet} {
    padding: 14px 46px;
    border: 2px solid white;
    color: white;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-size: 14px;
    font-weight: 700;
    margin-left: 24px;
    &:hover {
      transition: all 0.2s ease-in-out;
      background: #fff;
      color: #010606;
    }
  }
`;

export const NavLinkFooter = styled.a `
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  text-decoration: none;
  padding: 8px 0;
  cursor: pointer;
`;
