import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import CircularProgress from '@material-ui/core/CircularProgress';
import HeaderMobile from '../components/HeaderMobile';
import Sidebar from '../components/Sidebar';
import styled from 'styled-components';
import Form from '../components/Form';
import FinishView from '../components/FinishView';

const HolaSoy = styled.span``;
const Swarm = styled.span`
  color: #2240d9;
`;
const GoodDesignIsContainer = styled.b`
  position: relative;
  line-height: 120%;
`;
const Swarm1 = styled.b``;
const GoodDesignIsContainer1 = styled.div`
  align-self: stretch;
  position: relative;
  font-size: 16px;
  line-height: 120%;
`;
const GoodDesignIsParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0px;
`;
const ListElement = styled.li``;
const ListParent = styled.ul`
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  padding-left: 21px;
`;
const ListContainer = styled.div`
  align-self: stretch;
  position: relative;
  line-height: 120%;
`;
const QuestionaryContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 25px 0px;
  font-size: 16px;
  color: #0e1d39 !important;
`;
const QuestionConteiner = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 32px 0px;
  min-width: 300px;
  max-width: 900px;
  @media screen and (max-width: 420px) {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
    box-sizing: border-box;
  }
`;
const ResponseConteiner = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px 0px;
  min-width: 300px;
  max-width: 900px;
  color: #0e1d39 !important;
  @media screen and (max-width: 420px) {
    width: 100%;
    height: auto;
    align-items: center;
    justify-content: flex-start;
    padding: 0px;
    box-sizing: border-box;
  }
`;
const ResponseElement = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px 0px;
  font-size: 16px;
  color: #0e1d39 !important;
  & > p,
  ul,
  ol,
  div,
  ol > li > p,
  ul > li > p,
  ol > li > div,
  ul > li > d,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #0e1d39 !important;
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 14px 0px;
  }

  h2,
  h3,
  h4 {
    color: var(--PRIMARY-NAVY, #0e1d39);
    font-family: 'DM Sans';
    font-size: 19.22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
`;
const BodyContent = styled.div`
  position: relative;
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 96px 0px;
  gap: 48px 0px;
  overflow-y: auto;
  @media screen and (max-width: 420px) {
    gap: 20px 0px;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    box-sizing: border-box;
    flex: unset;
    align-self: stretch;
  }
`;
const ChatRoot = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 40px;
  color: #0e1d39 !important;
  font-family: 'DM Sans';
  @media screen and (max-width: 420px) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
`;

const LoaderContainer = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const initialAnswer = [
  '¿Podría contarnos brevemente sobre el core de tu negocio y tu modelo de negocio principal?',
  '¿Qué factores consideras que son clave para el crecimiento de tus ventas y márgenes?',
  '¿Quienes son tus competidores mas relevantes y que tienen ellos en tecnología que no tienen ustedes?',
  '¿Qué tecnologías apoyan el éxito y la eficiencia operativa de tu empresa?',
  'Basándote en te experiencia, ¿Dónde ve oportunidades de mejoras en tu negocio mediante la tecnología para aumentar ventas o reducir costos?',
  'Respecto al presupuesto para tecnología, ¿Podrías compartir cómo lo han distribuido hasta ahora? ¿Han preferido soluciones internas o externalizadas? ¿Por qué?',
  '¿Cuál es el desafío más grande que enfrenta tu empresa y que cree que podría ser resuelto con una solución tecnológica?',
  'Tiene algún presupuesto y tiempo esperado para la implementación de este proyecto?',
  '¿Existen limitaciones técnicas, financieras o de otro tipo que debamos tener en cuenta? ¿Cuáles son sus principales preocupaciones que podrían afectar el progreso del proyecto?',
  'Hasta ahora, ¿qué soluciones o tecnologías ha considerado para abordar este desafío? ¿Qué le ha frenado para implementarlas o por qué no han sido efectivas?',
  '¿Cómo definiría el éxito en la implementación de una nueva solución tecnológica para su empresa? ¿Existen métricas específicas o KPIs que esté buscando mejorar?',
  'Existe alguna limitacion o consideracion financiera o de tiempo que debamos considerar? Costo y tiempos de Implementacion.',
];

const ChatPage = () => {
  const [questions, setQuestions] = useState(initialAnswer);
  const [threads, setThreads] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [threadId, setThreadId] = useState('');
  const [isFinished, setIsFinished] = useState(false);
  return (
    <ChatRoot>
      <HeaderMobile
        setMessages={setMessages}
        setThreadId={setThreadId}
        setIsFinished={setIsFinished}
        threads={threads}
        setThreads={setThreads}
      />
      <Sidebar
        setMessages={setMessages}
        setThreadId={setThreadId}
        setIsFinished={setIsFinished}
        threads={threads}
        setThreads={setThreads}
      />
      <BodyContent>
        {isFinished ? (
          <FinishView threadId={threadId} />
        ) : (
          <QuestionConteiner>
            <GoodDesignIsParent>
              <GoodDesignIsContainer>
                <HolaSoy>{`¡Hola! soy `}</HolaSoy>
                <Swarm>Swarm</Swarm>
              </GoodDesignIsContainer>
              <GoodDesignIsContainer1>
                <HolaSoy>{`Prepárate para compartir toda la información que tengas sobre tu cliente. `}</HolaSoy>
                <Swarm1>SWARM</Swarm1>
                <HolaSoy>
                  {' '}
                  te guiará con una serie de preguntas para entender mejor tus
                  necesidades.
                </HolaSoy>
              </GoodDesignIsContainer1>
            </GoodDesignIsParent>

            {questions && questions.length ? (
              <QuestionaryContainer>
                {questions.map((message, index) => (
                  <ListContainer key={index}>
                    <ListParent>
                      <ListElement>{message}</ListElement>
                    </ListParent>
                  </ListContainer>
                ))}
              </QuestionaryContainer>
            ) : (
              <></>
            )}
          </QuestionConteiner>
        )}

        {messages && messages.length ? (
          <ResponseConteiner>
            {messages.map((item, index) => (
              <ResponseElement key={index}>
                <Swarm1>{item.name}</Swarm1>
                <ReactMarkdown>{item.message}</ReactMarkdown>
              </ResponseElement>
            ))}
          </ResponseConteiner>
        ) : (
          <></>
        )}

        {isLoading && (
          <LoaderContainer>
            <CircularProgress />
          </LoaderContainer>
        )}

        <Form
          isLoading={isLoading}
          threadId={threadId}
          setIsLoading={setIsLoading}
          setThreadId={setThreadId}
          isFinished={isFinished}
          setIsFinished={setIsFinished}
          setMessages={setMessages}
          setThreads={setThreads}
        />
      </BodyContent>
    </ChatRoot>
  );
};

export default ChatPage;
