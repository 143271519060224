import { useEffect, useState } from "react";
import { Nav, NavLink, NavBtn, NavBtnLink } from "./style/navbar-styles";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { isMobile } from "react-device-detect";

export default function LayoutAppNewie({ children }) {
  const [lang, setLang] = useState("");
  const [langOpen, setLangOpen] = useState(false);

  const { t } = useTranslation(["home"]);

  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
    if (localStorage.getItem("i18nextLng") === "es") {
      setLang("ESPAÑOL");
    }
    if (localStorage.getItem("i18nextLng") === "en") {
      setLang("ENGLISH");
    }
  }, []);

  const { i18n } = useTranslation();

  const handleLanguageChange = (e) => {
    if (e.target.innerText === "ESPAÑOL") {
      i18n.changeLanguage("es");
      setLang("ESPAÑOL");
    }
    if (e.target.innerText === "ENGLISH") {
      i18n.changeLanguage("en");
      setLang("ENGLISH");
    }
  };

  const handleLanguageChangeMobile = (value) => {
    if (value === "ESPAÑOL") {
      i18n.changeLanguage("es");
      setLang("ESPAÑOL");
    }
    if (value === "ENGLISH") {
      i18n.changeLanguage("en");
      setLang("ENGLISH");
    }
  };

  const hadleClick = () => {
    langOpen ? setLangOpen(false) : setLangOpen(true);
  };

  const isTeamContactCard = window.location.pathname.includes("team");

  if (!isTeamContactCard) {
    return (
      <>
        <Nav langOpen={langOpen}>
          <div
            className="lang-opacity"
            onClick={() => setLangOpen(false)}
          ></div>
          <NavLink to="/">
            <img
              className="logo-nav"
              src="/newelogo.png"
              alt="Logo Rocket Code Dark"
            />
          </NavLink>
          {/* <Bars /> */}
          {/* <NavMenu>
          <NavLink to='/case-studies' >
            Case Studies
          </NavLink>
          <NavLink to='/services' >
            Services
          </NavLink>
          <NavLink to='/about' >
            About
          </NavLink>
          <NavLink to='/blog' >
            Blog
          </NavLink>
          <NavA href='https://www.linkedin.com/company/the-rocket-code/jobs/?viewAsMember=true' target="_blank" >
            Careers
          </NavA>
        </NavMenu> */}
          <div style={{ display: "flex" }}>
            <div className="final__dropdown" onClick={hadleClick}>
              {isMobile ? (
                <button className="final__dropdown__hover"></button>
              ) : (
                <button className="final__dropdown__hover">{lang}</button>
              )}
              {!isMobile && (
                <div className="final__dropdown__menu">
                  {lang === "ESPAÑOL" ? (
                    <p onClick={(e) => handleLanguageChange(e)}>ENGLISH</p>
                  ) : (
                    <p onClick={(e) => handleLanguageChange(e)}>ESPAÑOL</p>
                  )}
                </div>
              )}
              {isMobile && (
                <div className="modal-lang">
                  <div
                    className="lang-mobile"
                    onClick={(e) => handleLanguageChangeMobile("ENGLISH")}
                  >
                    <p>ENGLISH</p>
                    {lang === "ENGLISH" ? (
                      <img src="./selected.svg" alt="selected" />
                    ) : (
                      <img src="./unselected.svg" alt="unselected" />
                    )}
                  </div>
                  <hr />
                  <div
                    className="lang-mobile"
                    onClick={(e) => handleLanguageChangeMobile("ESPAÑOL")}
                  >
                    <p>ESPAÑOL</p>
                    {lang === "ENGLISH" ? (
                      <img src="./unselected.svg" alt="unselected" />
                    ) : (
                      <img src="./selected.svg" alt="selected" />
                    )}
                  </div>
                </div>
              )}
            </div>
            <NavBtn>
              <NavBtnLink to="/contact">{t("header.cta-nav")}</NavBtnLink>
            </NavBtn>
          </div>
        </Nav>

        {children}

        <Footer />
      </>
    );
  } else {
    return <>{children}</>;
  }
}
